<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" class="wizard-step">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- 1 -->
        <v-tab-item>
          <v-card>
            <v-card-text>
              <form>
                <div>
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Korporativ Karta Malumotlarini o'zgartirish
                  </h4>
                  <div>
                    <div>
                      <!-- {{ getCorpDetails }} -->
                      <!-- {{getCorpDetails.owner_name.id}} -->
                      <!-- {{getCorpDetails.id}} -->
                      <!-- {{ getCorpDetails.owner_name }} -->

                      <!-- {{getCorpDetails}} -->
                      <!-- {{ getCorpDetails.owner_name }} -->
                      <!-- {{ nameFull }} -->
                      <!-- {{getCorpDetails.owner}} -->

                      <!-- {{getAllDriverNames}} -->
                      <!-- {{select}} -->
                      <!-- {{getCurrname}} -->
                    </div>
                    <!-- {{ getCorpDetails }} -->
                    <!-- {{ getCorpDetails.owner_name }} -->
                    <!-- {{ getCorpDetails.owner }} -->

                    <!-- {{ getCorpDetails.owner_name.full_name }}
                    {{ getCorpDetails.owner_name.owner }} -->
                    <!-- <p>------------</p> -->
                    <!-- {{ getCorpDetails.owner_name }} -->
                    <!-- {{getNameOwner}}

                      
                      
                      {{getNameOwner.owner}}
                      {{getNameOwner.full_name}} -->
                      <!-- {{ getCorpDetails.owner_name }} -->
                      <!-- {{ getCurrname.full_name }} -->
                      <!-- {{ getAllDriverNames }} -->
                    <v-combobox
                      v-model="getCurrname.full_name"
                      :items="getAllDriverNames"
                      label="Ism Familiya"
                      dense
                      item-text="full_name"
                      outlined
                      :loading="!getAllDriverNames.length > 0"
                    >
                    </v-combobox>
                  </div>
                  <!-- {{ getCurrname}} -->
                  <!-- <v-text-field
                    disabled
                    label="Ism Familiya"
                    outlined
                    v-model="getCorpDetails.owner_name"
                  ></v-text-field> -->
                  <v-text-field
                    outlined
                    label="Mashina raqami"
                    enabled
                    :value="getCorpDetails.owner_truck"
                  ></v-text-field>

                  <v-dialog
                    ref="dialog"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="getCorpDetails.expire"
                        label="Belgilangan sana"
                        disabled
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                  </v-dialog>
                  <v-text-field
                    outlined
                    label="Karta raqami"
                    maxlength="16"
                    v-model="getCorpDetails.number_16_digit"
                  ></v-text-field>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Yangi sana"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="modal = false"
                        >Bekor qilish</v-btn
                      >
                      <v-btn
                        small
                        color="success"
                        @click="$refs.dialog.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>

                  <v-text-field
                    outlined
                    v-model="getCorpDetails.remain"
                    label="Qoldik"
                  ></v-text-field>
                </div>
                <v-btn class="mr-4 w-100" color="success" @click="submitUpdate"
                  >Saqlash</v-btn
                >
              </form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import Swal from "sweetalert2";
export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      select: {},
      modal: false,

      menu2: false,
      name: '',
      tab: null,
      items: [
        {
          tab: "Malumotlarini o'zgartirish"
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getCorpCardDetails', this.$route.params.id)
    this.$store.dispatch('getAllDrivers')
  },
  computed: {
    getCorpDetails() {
      console.log(this.$store.state.requests.corpDetails, 'CORP_DET');
      return this.$store.state.requests.corpDetails
    },
    getCurrname() {
      const name = {
        full_name: this.$store.state.requests.corpDetails.owner_name
      }
      return name
    },
    getid() {
      return this.$store.state.requests.corpDetails.owner
    },
    getAllDrivers() {
      return this.$store.state.requests.allDrivers
    },
    getAllDriverNames() {
      const dataNames = []
      this.$store.state.requests.allDrivers?.forEach((element) => {
        // console.log(element, 'just element')
        // console.log(element.staff_description.id, element.staff_description.staffpersonal_description.first_name, 'element');
        const name = {
          full_name: element.staff_description.staffpersonal_description.first_name + ' ' + element.staff_description.staffpersonal_description.last_name,
          owner: element.staff_description.id
        }
        dataNames.push(name)
      })
      return dataNames
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: "Korporativ Karta Malumotlarini o'zgartirish" }
    ])
  },
  methods: {
    submitUpdate() {
      if (
          this.getCurrname.full_name ==
          this.$store.state.requests.corpDetails.owner_name
      ) {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'

        const id = {
          id: this.$route.params.id
        }
        const data = {
          number_16_digit: this.getCorpDetails.number_16_digit.trim(),
          expire: this.date,
          remain: this.getCorpDetails.remain,
          status: true,
          owner: this.getid
        }
        this.$store.dispatch('updateCorpCard', { id, data })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const id = {
          id: this.$route.params.id
        }
        const data = {
          number_16_digit: this.getCorpDetails.number_16_digit.trim(),
          expire: this.date,
          remain: this.getCorpDetails.remain,
          status: true,
          owner: this.getCurrname.full_name.owner
        }
        this.$store.dispatch('updateCorpCard', { id, data })
      }
    }
  }
}
</script>

<style></style>
